<template>
  <div 
    style="width:100%;height:100%;background-color:transparent;">
    <report-layout/>
  </div>
</template>

<script>

export default {
  name: 'History',
  data () {
    return {
    }
  }
}
</script>